import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-plus/theme-chalk/display.css'
//引入nprogress
// import NProgress from 'nprogress' // 进度条
// import 'nprogress/nprogress.css' //这个样式必须引入

// router.beforeEach((to,from,next) => {
//     NProgress.start() 
//     next()
//   })
  
//   router.afterEach(() => {
//     NProgress.done()
//   })

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
