
import modelM from "@/components/modelM.vue"
import material from "./material.vue"
import devise from "./devise.vue"
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    modelM,
    material,
    devise
  },
  name: "HelloWorld",
  props: {
    msg: String,
  },
  setup() {
    const count = ref(0);
    const load = () => {
      count.value += 2;
    };
    return {
      count,
      load,
    };
  },
});
